var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('section',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Basic information")])])],1)],1),_c('v-divider'),(_vm.loading)?_c('section',{staticClass:"py-16"},[_c('app-loading')],1):_c('section',{staticClass:"py-4"},[(_vm.errorMessage.length || _vm.updated)?_c('section',{staticClass:"mb-4"},[(_vm.errorMessage.length)?_c('v-alert',{attrs:{"prominent":"","type":"error","dense":"","close-icon":"my-4","outlined":"","text":"","shaped":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('p',[_vm._v("Error: "+_vm._s(_vm.errorMessage))])])],1)],1):_vm._e(),(_vm.updated)?_c('v-alert',{attrs:{"prominent":"","type":"info","dense":"","close-icon":"my-4","outlined":"","text":"","shaped":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('p',[_vm._v("Updated")])])],1)],1):_vm._e()],1):_vm._e(),_c('section',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"First name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name*","name":"First name","outlined":"","error-messages":errors,"required":""},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Last name","rules":"required_field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last name*","name":"Last name","required":"","outlined":""},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Phone number","name":"Phone number","outlined":""},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('v-text-field',{attrs:{"label":"Job Title/Profession","outlined":""},model:{value:(_vm.form.profession),callback:function ($$v) {_vm.$set(_vm.form, "profession", $$v)},expression:"form.profession"}}),_c('section',[_c('p',[_vm._v("Required fields are marked with an asterisk *")])])],1)],1),_c('v-divider',{staticClass:"mb-8"}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"type":"submit","color":"accent","large":"","rounded":""}},[_vm._v("Update")])],1)],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }