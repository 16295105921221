














































































































import Vue from "vue";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { HealthCareSpecialities } from "@/providers/HealthCareSpecialities";
import AppLoading from "@/components/layout/AppLoading.vue";
import { mapActions, mapGetters } from "vuex";
import {
  ApiException,
  UserAccountUpdateBasicInformationCommand,
} from "@/clients/clients";
import { required } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required_field", {
  ...required,
  message: "{_field_} can not be empty",
});

export default Vue.extend({
  name: "ProfileBasicInformationView",
  components: { AppLoading, ValidationObserver, ValidationProvider },
  data: () => ({
    errorMessage: "",
    updated: false,
    loading: false,
    form: new UserAccountUpdateBasicInformationCommand(),
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
    specialities(): string[] {
      return HealthCareSpecialities;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("UserStore", ["updateBasicInformation"]),
    init(): void {
      if (this.user) {
        const data = new UserAccountUpdateBasicInformationCommand();
        data.init(this.user);
        this.form = Object.assign({}, this.form, data);
      }
    },
    async onSubmit() {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.updateBasicInformation(this.form);
        this.updated = true;
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
